<template>
    <div class="resources">
    <v-col cols="12" sm-11>
      <v-card>
        <v-card-title>
          <h3>Resources</h3>
          <v-spacer></v-spacer>
          <v-text-field v-model="searchtext" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
        </v-card-title>
        <v-container fluid>
          <v-row v-if="firstloadcomplete">
            <v-col lg="3" md="4" sm="5" xs="6" cols="12">
              <span>Selection Filters</span>
              <v-expansion-panels multiple>
                <v-expansion-panel>
                  <v-expansion-panel-header>Resource Category</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list>
                      <v-list-item-group v-model="rsrc_selected_types" multiple active-class="">
                      <v-list-item
                        v-for="item in rsrc_available_types" :key="`types-${item.value}`" :value="item.value">
                        <template v-slot:default="{ active }">
                          <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>{{ item.title }} ({{item.count}})</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Pump Variants</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list>
                      <v-list-item-group v-model="rsrc_selected_variants" multiple active-class="">
                      <v-list-item
                        v-for="item in rsrc_available_variants" :key="`variants-${item.value}`" :value="item.value">
                        <template v-slot:default="{ active }">
                          <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>{{ item.title }} ({{item.count}})</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-btn class="ml-3 my-3" color="primary" @click.prevent="refresh_resources_list">Apply</v-btn>
            </v-col>
            <v-col lg="9" md="8" sm="7" xs="6" cols="12">
              <v-data-table v-if="rclist.length > 0" :items="rclist" :headers="headers" hide-default-header
                :search="searchtext" must-sort :sort-by.sync="sortResourcesBy" :sort-desc.sync="sortResourcesDesc"
                :footer-props="{'items-per-page-options':[10, 20, 30, -1]}" :items-per-page="10">
                  <template v-slot:item="{ item }">
                    <v-card outlined elevation="2" class="mb-3 hover-highlight">
                      <v-card-title>
                        <router-link :to="'/resource/'+item.title[0]"><div class="text-h5">{{ item.title[1] }}</div></router-link>
                        <v-spacer></v-spacer>
                        <v-btn v-if="item.dllink != null" :href="$rsrcdburl+'/files/download/'+item.dllink" outlined>
                          Download ({{ format_file_size(item.filesize) }})
                          <v-icon right dark>mdi-tray-arrow-down</v-icon>
                        </v-btn>
                        <v-btn dark small color="error" v-if="view_edit_controls" @click="deleteEntryClick(item.id)" class="ml-3">
                          <v-icon dark> mdi-minus </v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-subtitle>
                        {{ item.type }} | {{  new Date(item.publishdate).toLocaleDateString("en-US",{year: 'numeric', month: 'short', day: 'numeric'}) }}
                      </v-card-subtitle>
                    </v-card>
                  </template>
              </v-data-table>
              <v-btn v-if="view_edit_controls" color="primary" dark to="resource/new">Add Page</v-btn>
              <v-dialog v-model="deleteEntryDialog" width="500">
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2"> Confirm Deletion </v-card-title>
                  <v-card-text> Are you sure you want to delete ? </v-card-text>
                  <v-card-text> {{ delentry_error_msg }} </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="deleteEntryConfirm" > Delete </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog> 
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
    </div>
</template>
<script>
export default ({
    name: "ResourcesList",
    data: () => ({
        firstloadcomplete: false,
        view_edit_controls: false,
        
        rsrc_available_types: [],
        rsrc_selected_types:[],
        
        rsrc_available_variants: [],
        rsrc_selected_variants:[],

        rclist: [],
        headers: [
          { text: 'Type', value: 'type', filterable: true, sortable: false },
          { text: 'Title', value: 'title', filterable: true, sortable: false },
          { text: 'Variants', value: 'variants', filterable: true, sortable: false },
          { text: 'Publish Date', value: 'publishdate', filterable: false, sortable: true },
          //{ text: 'Download', value: 'dllink', filterable: false, sortable: false },
          //{ text: 'Review Date', value: 'reviewdate', filterable: false, sortable: false },
          //{ text: 'Reviewed By', value: 'author', filterable: false, sortable: false },
          //{ text: 'Delete', value: 'id', filterable: false, sortable: false }
        ],
        searchtext:"",
        sortResourcesBy: 'publishdate', sortResourcesDesc: true,

        deleteEntry: {"id":"","type":"","title":"", "content":""},
        deleteEntryIdx: null,
        delentry_error_msg: null,
        deleteEntryDialog: false,
    }),
  /*computed : {
    secured_headers () {
      if (this.view_edit_controls)
        return this.headers;
      else
        return this.headers.filter(x => x.value != 'id');
    },
  },*/
  mounted(){
    //this.$emit('loadstart');
    const useraccessdict = this.$store.getters.get_useraccessdict();
    //console.log(useraccessdict);
    this.view_edit_controls = (useraccessdict != null) && useraccessdict["edit_resources"];
    if (this.view_edit_controls)
      this.headers.push({ text: 'Delete', value: 'id', filterable: false, sortable: false });
    this.refresh_resources_list();
  },
  methods: {
    refresh_resources_list() {
      this.$emit('loadstart');
      this.$api.post(this.$rsrcdburl + "/list", {'code':'get_resources_list', 'types':this.rsrc_selected_types, 'variants':this.rsrc_selected_variants})
      .then(response => {
        //console.log(response.data);
        if((response.data["code"] == "get_resources_list") && response.data["success"]) {
          const rsrcvariants = response.data["variantslist"];
          const rsrctypes = response.data["typeslist"];
          const rsrclist = response.data["resourcelist"];
          this.rsrc_available_variants = rsrcvariants.map(function(x) { 
              const dict = {"title":x[0].toUpperCase(), "value":x[0], "count":x[1]}
              return dict;
            }
          );
          this.rsrc_available_types = rsrctypes.map(function(x) { 
              const dict = {"title":x[0].toUpperCase(), "value":x[0], "count":x[1]}
              return dict;
            }
          );
          this.rclist = rsrclist.map(function(x) { 
              var dict = {"id":x.id,"type":x.type,"title":[x.id,x.title],"filesize":x.filesize,
                            "publishdate":x.publishdate,"reviewdate":x.reviewdate,"author":x.author}
              if(x.type != "video")  dict["dllink"] = x.download;
              return dict;
            }
          );
        } else {
          //alert("Error in retreiving resources");
        }
        this.firstloadcomplete = true;
        this.$emit('loadcomplete');
        //alert(typeof this.dtc_details.details);
      });
    },
    format_file_size(fsize){
      var strfsize = "";
      if (fsize != undefined) { 
        if (fsize > 1073741824) {
          strfsize = (fsize/1073741824).toFixed(2) + "GB";
        } else if (fsize > 1048576) {
          strfsize = (fsize/1048576).toFixed(1) + "MB";
        } else if (fsize > 1024) {
          strfsize = ((fsize >> 10)+1).toString() + "KB";
        } else {
          strfsize = fsize.toString() + "B";
        }
      }
      return strfsize;
    },
    deleteEntryClick(rcid){
        const idx = this.rclist.findIndex(x => x.id == rcid);
        this.deleteEntry = this.rclist[idx];
        this.deleteEntryIdx = idx;
        this.delentry_error_msg = null;
        this.deleteEntryDialog = true;
    },
    deleteEntryConfirm(){
      this.$emit('loadstart');
      const deldata = {"code":"delete_page","id":this.deleteEntry['id']};
      console.log(deldata);
      this.$api.post(this.$rsrcdburl + "/delete", deldata)
      .then(response => {
        if (response.data['code'] == 'delete_page') {
        if (response.data['success'])
        {
            console.log("success");
            this.deleteEntryDialog = false;
            this.rclist.splice(this.deleteEntryIdx, 1);
        } else {
            this.delentry_error_msg = response.data['errmsg'];
        }
        } else {
        this.delentry_error_msg = "Invalid response!";
        }});
    }
  }
})
</script>

<style scoped>
a {
  text-decoration: none;
}

.hover-highlight:hover {
  border-left:3px inset rgb(128, 128, 255);
  -webkit-box-shadow: 5px 5px 15px rgba(0,0,0,0.4);
  -moz-box-shadow: 5px 5px 15px rgba(0,0,0,0.4);
}

/*@import url('https://fonts.googleapis.com/css?family=Oxygen:300,400,700&display=swap');
.text-h5 {
  font-family: "Oxygen" !important;
}*/
.text-h5:hover {
  text-decoration: underline;
}
</style>