export default {
    convertLocalDateToUTCDate(datestr){
        var localDatePlusTimezone = new Date(datestr);//parses it as UTC datetime
        var localdate = new Date(localDatePlusTimezone.getTime()+localDatePlusTimezone.getTimezoneOffset()*60*1000);
        var utcdatestr = localdate.toISOString().replace("T"," ").replace("Z","");
        /*var now = new Date(Date.now());
        console.log(now.toISOString(), localdate.getTimezoneOffset()*60*1000,localdate, utcdatestr);*/
        return utcdatestr;
    },
    convertUTCDateToLocalDate(datestr) {        
        var utcdate = new Date(datestr);
        var localDate = new Date(utcdate.getTime()-utcdate.getTimezoneOffset()*60*1000);

        /*var offset = utcdate.getTimezoneOffset() / 60;
        var hours = utcdate.getHours();

        localDate.setHours(hours - offset);*/

        return localDate.toLocaleString();
    },
    convertUTCDateToSortableLocalDate(datestr) {  
        const timestamp = Date.parse(datestr);   
        if (isNaN(timestamp))
            return "2000-01-01";

        const utctimeAslocaltime = new Date(timestamp);
        const utcdate = new Date(utctimeAslocaltime.getTime()-utctimeAslocaltime.getTimezoneOffset()*60*1000);
        const localDate = new Date(utcdate.getTime()-utcdate.getTimezoneOffset()*60*1000);

        /*const month = (localDate.getMonth() + 1);
        const day = localDate.getDate();
        const year = localDate.getFullYear();
        const hours = utcdate.getHours();
        const minutes = utcdate.getMinutes();
        const seconds = utcdate.getSeconds();*/
        /*var offset = utcdate.getTimezoneOffset() / 60;

        localDate.setHours(hours - offset);*/
        //const yyyymmdd_hhmmss = year +"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
        return localDate.toISOString().replace("T"," ").substring(0, 19);
    },
    convertUTCDateToReadableLocalDate(datestr) {  
        const timestamp = Date.parse(datestr);   
        if (isNaN(timestamp))
            return "2000-01-01";

        const utctimeAslocaltime = new Date(timestamp);
        const utcdate = new Date(utctimeAslocaltime.getTime()-utctimeAslocaltime.getTimezoneOffset()*60*1000);
        const localDate = new Date(utcdate.getTime()-utcdate.getTimezoneOffset()*60*1000);

        var options = { year: 'numeric', month: 'short', day: 'numeric', hour:'numeric',minute:'numeric' }; 
        return localDate.toLocaleDateString("en-US", options);
    }
}