<template>
  <div class="pumphealth">
          <!--v-card>
            <v-card-title>
              <h3>PumpHealth - {{ pumpcname }}</h3>          
            </v-card-title>
            <v-card-subtitle>
              {{ pumpnname }} ({{ pumpip }})
            </v-card-subtitle>
          </v-card-->
            
          <v-container>
            <v-row>
              <v-col cols="12" sm="7"><h3>PumpHealth - {{ pumpcname }}</h3>{{ pumpnname }} (<a :href="`pumpprogress?pumpip=${pumpip}`">{{ pumpip }}</a>)</v-col>
              <v-col cols="6" sm="3">
                  <v-menu v-model="searchreqdatemenu" :close-on-content-click="false" :nudge-right="40" 
                  transition="scale-transition" offset-y min-width="auto" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="requestdate" readonly v-bind="attrs" v-on="on"
                      label="Select Report Date" prepend-icon="mdi-calendar"                    
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="requestdate" @input="searchreqdatemenu = false" ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" sm="2">
                <v-btn color="primary" @click.prevent="get_health_report(pumpip,requestdate)">Get Report</v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-if="healthhash != null">
            <v-row >
              <v-col>
                  <v-simple-table dense>
                      <template v-slot:default>
                          <tbody>
                            <tr><td><b>Data Collection Time</b></td><td>{{ convertUTCDateToLocalDate(connstate['rtime']) }}</td></tr>
                            <tr><td><b>Firmware version</b></td><td>{{ espcmVersion }}</td></tr>
                            <tr><td><b>Bootloader version</b></td><td>{{ bootldrVersion }}</td></tr>
                            <tr><td><b>SerialNumber</b></td><td>{{ pumpsernumprefix }}-{{ daqdata[psernumi] }}</td></tr>
                            <tr><td><b>Variant</b></td><td>{{ pumpvariant }}</td></tr>
                            <tr><td><b>Pumps Installed</b></td><td>{{ daqdata[instpcounti] }}</td></tr>
                            <tr><td><b>Pumps Enabled</b></td><td>{{ enPumpEnableStatus }}</td></tr>
                            <tr><td><b>Total Ontime</b></td><td>{{ total_ontime }}</td></tr>
                            <tr><td><b>Total Runtime</b></td><td>{{ total_runtime }}</td></tr>
                            <tr><td><b>Total Runtime(HMI)</b></td><td>{{ total_runtime_hmi }}</td></tr>
                          </tbody>
                      </template>
                  </v-simple-table>
                </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-center align-center">
                <v-card>
                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th><font size="3">Pump 1</font></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                              <tr><td><b>IndexPulse Calibration Angle</b></td><td>{{ daqdata[p1_zpulse_offseti].toFixed(2) }}°</td></tr>
                              <tr><td><b>StrokeLength</b></td><td>{{ daqdata[p1strokeleni].toFixed(2) }}mm</td></tr>
                              <tr><td><b>StrokeCount</b></td><td>{{ daqdata[p1strokecounti] }}</td></tr>
                              <!--tr><td><b>Backdrive Velocity Forward</b></td><td>{{ daqdata[p1_backdrive_velfwd_i].toFixed(2) }}mm/s</td></tr>
                              <tr><td><b>Backdrive Velocity Reverse</b></td><td>{{ daqdata[p1_backdrive_velrev_i].toFixed(2) }}mm/s</td></tr-->
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
              </v-col>
              <v-col v-if="daqdata[instpcounti]>1" class="d-flex justify-center align-center">
                <v-card>
                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th><font size="3">Pump 2</font></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                              <tr><td><b>IndexPulse Calibration Angle</b></td><td>{{ daqdata[p2_zpulse_offseti].toFixed(2) }}°</td></tr>
                              <tr><td><b>StrokeLength</b></td><td>{{ daqdata[p2strokeleni].toFixed(2) }}mm</td></tr>
                              <tr><td><b>StrokeCount</b></td><td>{{ daqdata[p2strokecounti] }}</td></tr>
                              <!--tr><td><b>Backdrive Velocity Forward</b></td><td>{{ daqdata[p2_backdrive_velfwd_i].toFixed(2) }}mm/s</td></tr>
                              <tr><td><b>Backdrive Velocity Reverse</b></td><td>{{ daqdata[p2_backdrive_velrev_i].toFixed(2) }}mm/s</td></tr-->
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <v-container fluid v-if="healthhash != null">
            <v-row>
              <v-col class="d-flex justify-center align-center" cols="12" md="6">
                <!--{{ daqdata[stats_tvspsi_i].length }}-->
                <apexchart :width="graphwidth" height="300" type="bar" :options="ChartOptions_psiTimeStats" :series="psiTimeStatsSeries"></apexchart>
              </v-col>
              <v-col class="d-flex justify-center align-center" cols="12" md="6">
                <!--{{ daqdata[p1stats_stcvspsi_i].length }}
                {{ daqdata[p2stats_stcvspsi_i].length }}-->
                <apexchart :width="graphwidth" height="300" type="bar" :options="ChartOptions_psiStrokeStats" :series="psiStrokeStatsSeries"></apexchart>
              </v-col>
              <v-col class="d-flex justify-center align-center" cols="12" md="6">
                <!--{{ daqdata[stats_tvspsi_i].length }}-->
                <apexchart :width="graphwidth" height="300" type="bar" :options="ChartOptions_strokesRevProxStats" :series="strokesRevProxStatsSeries"></apexchart>
              </v-col>
              <v-col class="d-flex justify-center align-center" cols="12" md="6">
                <!--{{ daqdata[stats_tvspsi_i].length }}-->
                <apexchart :width="graphwidth" height="300" type="bar" :options="ChartOptions_strokesFwdProxStats" :series="strokesFwdProxStatsSeries"></apexchart>
              </v-col>
              <v-col class="d-flex justify-center align-center" cols="12" md="6">
                <!--{{ daqdata[p1stats_tempdrive_i].length }}
                {{ daqdata[p2stats_tempdrive_i].length }}-->
                <apexchart :width="graphwidth" height="300" type="bar" :options="ChartOptions_tempDriveStats" :series="temperatureDriveseries"></apexchart>
              </v-col>
              <v-col class="d-flex justify-center align-center" cols="12" md="6">
                <!--{{ daqdata[p2stats_tempespcm_i].length }}
                {{ daqdata[p2stats_tempespcm_i].length }}-->
                <apexchart :width="graphwidth" height="300" type="bar" :options="ChartOptions_tempESPCMStats" :series="temperatureESPCMseries"></apexchart>
              </v-col>
              <v-col class="d-flex justify-center align-center" cols="12" md="6">
                <!--{{ daqdata[p1stats_tempmotor_i].length }}
                {{ daqdata[p2stats_tempmotor_i].length }}-->
                <apexchart :width="graphwidth" height="300" type="bar" :options="ChartOptions_tempMotorStats" :series="temperatureMotorseries"></apexchart>
              </v-col>
              <v-col class="d-flex justify-center align-center" cols="12" md="6">
                <!--{{ daqdata[p1stats_tempmotor_i].length }}
                {{ daqdata[p2stats_tempmotor_i].length }}-->
                <apexchart :width="graphwidth" height="300" type="bar" :options="ChartOptions_tempOilHotStats" :series="temperatureOilHotseries"></apexchart>
              </v-col>
              <v-col class="d-flex justify-center align-center" cols="12" md="6">
                <!--{{ daqdata[p1stats_tempmotor_i].length }}
                {{ daqdata[p2stats_tempmotor_i].length }}-->
                <apexchart :width="graphwidth" height="300" type="bar" :options="ChartOptions_tempOilColdStats" :series="temperatureOilColdseries"></apexchart>
              </v-col>
              <v-col class="d-flex justify-center align-center" cols="12" md="6">
                <!--{{ daqdata[p1stats_tempmotor_i].length }}
                {{ daqdata[p2stats_tempmotor_i].length }}-->
                <apexchart :width="graphwidth" height="300" type="bar" :options="ChartOptions_tempCoolWaterStats" :series="temperatureCoolWaterInseries"></apexchart>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3>Modified Parameters</h3> 
              </v-col>
              <v-col><v-text-field v-model="nondefsearch" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table :headers="nondefheaders" :items="nondefaultslist" :search="nondefsearch"
                  :footer-props="{'items-per-page-options':[5, 10, 30, -1]}" :items-per-page="10" dense
                  must-sort :sort-by.sync="sortNonDefsBy" :sort-desc.sync="sortNonDefsDesc" >
                  <template #item.defvalue="{ item }">
                    {{ item.defvalue }}
                  </template>
                  <template #item.currvalue="{ item }">
                    {{ item.currvalue }}
                  </template>
                  <template #item.datetime="{ item }">
                    {{ convertUTCDateToLocalDate(item.datetime) }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
          <span v-else>{{ report_text }}</span>
  </div>
</template>

<script>
  import mcdenums from '../plugins/mcdenums';
  import dateutils from '../plugins/date_utilities';

  export default {
    name: 'PumpHealthReport',
    data () {
      return {
        loading: true,
        pumpip:null,pumpcname:null,pumpnname:null,
        searchreqdatemenu: false,
        //requestdate: "2000-01-01", 
        requestdate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        
        healthhash:null,
        healthprofile:[],
        connstate:{},
        daqdata:{},
        report_text:"",

        appfwveri:null,bootldrfwveri:null,
        cmsernumi:null, psernumpi:null, psernumi:null,
        pvarianti:null,
        instpcounti:null, enpumpflgsi:null,

        timetotalrunhmii:null,
        timetotalruni:null,
        timeoni:null,
        stats_tvspsi_i:null,
        stats_tvsohot_i:null,
        stats_tvsocold_i:null,
        stats_tvswaterin_i:null,

        p1_zpulse_offseti:null,
        p1strokeleni:null,
        p1strokecounti:null,
        //p1_backdrive_velfwd_i:null,
        //p1_backdrive_velrev_i:null,
        p1stats_stcvspsi_i:null,
        p1stats_tempdrive_i:null,
        p1stats_tempespcm_i:null,
        p1stats_tempmotor_i:null,
        p1stats_stcvsfwdprox_i:null,
        p1stats_stcvsrevprox_i:null,

        p2_zpulse_offseti:null,
        p2strokeleni:null,
        p2strokecounti:null,
        //p2_backdrive_velfwd_i:null,
        //p2_backdrive_velrev_i:null,
        p2stats_stcvspsi_i:null,
        p2stats_tempdrive_i:null,
        p2stats_tempespcm_i:null,
        p2stats_tempmotor_i:null,
        p2stats_stcvsfwdprox_i:null,
        p2stats_stcvsrevprox_i:null,


        graphwidth:450,//px
        ChartOptions: {
          chart:{
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            title:{text:''},
            categories: [],
            labels: {
              formatter: (val) => { return val },
            }
          },
          yaxis: {
            decimalsInFloat:0,
            title: { text: '' },
            labels: {
              formatter: (val) => { return val },
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            x: {formatter: (value) => value + "°C" },
            y: { 
              formatter: function (val) { 
                  return "$ "+val+"PSI"; 
                } 
              },
            /*custom: function(series,seriesIndex,dataPointIndex) {
              return '<div><span>'+series[seriesIndex][dataPointIndex]+'</span></div>';
            }*/
          },
          legend:{show:true,position:'top'}
        },

        nondefsearch: "",
        nondefheaders:[
          { text: 'Parameter', value: 'instref', filterable: true, sortable: true },
          //{ text: 'ProfileHash', value: 'profilehash', filterable: false, sortable: false },
          //{ text: 'Index', value: 'index', filterable: true, sortable: true },
          { text: 'Datetime', value: 'datetime', filterable: false, sortable: false },
          { text: 'Default Value', value: 'defvalue', filterable: false, sortable: false },
          { text: 'Current Value', value: 'currvalue', filterable: false, sortable: false },
          { text: 'units', value: 'units', filterable: false, sortable: false }
        ],
        sortNonDefsBy: 'index', sortNonDefsDesc: true,
        nondefaultslist:[],

      }
    },
    computed: {
      espcmVersion: function() {
        return String.fromCharCode.apply(null,this.daqdata[this.appfwveri]);
      },
      bootldrVersion: function() {
        return String.fromCharCode.apply(null,this.daqdata[this.bootldrfwveri]);
      },
      totalhours: function() {
        const daqdata = this.pump["daq"];
        return daqdata[this.totalhoursi]/60;
      },
      total_ontime: function() {
        return this.get_hoursmins_format(this.daqdata[this.timeoni]);
      },
      total_runtime: function() {
        return this.get_hoursmins_format(this.daqdata[this.timetotalruni]);
      },
      total_runtime_hmi: function() {
        return this.get_hoursmins_format(this.daqdata[this.timetotalrunhmii],true);
      },
      pumpvariant () {
        return mcdenums.MachineConfiguration_tenPumpVariants[this.daqdata[this.pvarianti]];
      },
      pumpsernumprefix(){
        return mcdenums.MachineConfiguration_tenPumpSerialNumberPrefix[this.daqdata[this.psernumpi]];
      },
      enPumpEnableStatus() {
          var pumpenstatus = "N/A";
          if (this.daqdata[this.enpumpflgsi] != null) {
            if ((this.daqdata[this.enpumpflgsi] & 0b01)==0b01) pumpenstatus = "On";
            else pumpenstatus = "Off";
            if (this.daqdata[this.instpcounti] > 1){
              if ((this.daqdata[this.enpumpflgsi] & 0b10)==0b10) pumpenstatus = pumpenstatus + "-On";
              else pumpenstatus = pumpenstatus + "-Off";
            }
          }
          return pumpenstatus;
      },
      ChartOptions_psiTimeStats(){
        var choptions = JSON.parse(JSON.stringify(this.ChartOptions));
        choptions['title'] = {'text': 'Time at Pressure'};
        choptions['xaxis']['title']['text'] = 'Pressure (KSI)';
        //choptions['xaxis']['categories'] = Array.from({length: 20}, (_, i) => i * 4.096);
        choptions['xaxis']['categories'] = Array.from({length: 19}, (_, i) => i * 4.096);
        if (this.healthhash != null){
          const statslen = this.daqdata[this.stats_tvspsi_i].length;
          choptions['xaxis']['categories'] = Array.from({length: statslen-1}, (_, i) => (i+1) * 4.096);
        }
        //choptions['xaxis']['labels']['formatter'] = this.chart_xaxis_pressure_format;
        choptions['yaxis']['title']['text'] = 'Time';
        choptions['yaxis']['labels']['formatter'] = this.chart_yaxis_format;
        choptions['tooltip']['x']['formatter'] = this.chart_tooltip_xaxis_pvst_format; 
        choptions['tooltip']['y']['formatter'] = this.chart_tooltip_yaxis_format;  
        return choptions;
      },
      ChartOptions_psiStrokeStats(){
        var choptions = JSON.parse(JSON.stringify(this.ChartOptions));
        choptions['title'] = {'text': 'Strokes at Pressure'};
        choptions['xaxis']['title']['text'] = 'Pressure (KSI)';
        //choptions['xaxis']['categories'] = Array.from({length: 20}, (_, i) => i * 4.096);
        choptions['xaxis']['categories'] = Array.from({length: 19}, (_, i) => i * 4.096);
        if (this.healthhash != null){
          const p1statslen = this.daqdata[this.p1stats_stcvspsi_i].length;
          //const p2statslen = this.daqdata[this.p2stats_stcvspsi_i].length;
          choptions['xaxis']['categories'] = Array.from({length: p1statslen-1}, (_, i) => (i+1) * 4.096);
        }
        //choptions['xaxis']['labels']['formatter'] = this.chart_xaxis_pressure_format;
        choptions['yaxis']['title']['text'] = 'Strokes';
        choptions['yaxis']['labels']['formatter'] = this.chart_yaxis_format;
        choptions['tooltip']['x']['formatter'] = this.chart_tooltip_xaxis_pvsst_format; 
        choptions['tooltip']['y']['formatter'] = this.chart_tooltip_yaxis_format;  
        return choptions;
      },
      ChartOptions_strokesFwdProxStats(){
        var choptions = JSON.parse(JSON.stringify(this.ChartOptions));
        choptions['title'] = {'text': 'Strokes near Forward EndStop'};
        choptions['xaxis']['title']['text'] = 'Distance to Cyl2 EndStop (mm)';
        //choptions['xaxis']['categories'] = Array.from({length: 8}, (_, i) => i * 1.953125);
        choptions['xaxis']['categories'] = Array.from({length: 8}, (_, i) => i );
        if (this.healthhash != null){
          const p1statslen = this.daqdata[this.p1stats_stcvsfwdprox_i].length;
          //const p2statslen = this.daqdata[this.p2stats_stcvsfwdprox_i].length;
          choptions['xaxis']['categories'] = Array.from({length: p1statslen}, (_, i) => i );
        }
        //choptions['xaxis']['labels']['formatter'] = this.chart_xaxis_pressure_format;
        choptions['yaxis']['title']['text'] = 'Strokes';
        choptions['xaxis']['labels']['formatter'] = this.chart_xaxis_distance_format;
        choptions['yaxis']['labels']['formatter'] = this.chart_yaxis_format;
        choptions['tooltip']['x']['formatter'] = this.chart_tooltip_xaxis_proxvsst_format; 
        choptions['tooltip']['y']['formatter'] = this.chart_tooltip_yaxis_format;  
        return choptions;
      },
      ChartOptions_strokesRevProxStats(){
        var choptions = JSON.parse(JSON.stringify(this.ChartOptions));
        choptions['title'] = {'text': 'Strokes near Reverse EndStop'};
        choptions['xaxis']['title']['text'] = 'Distance to Cyl1 EndStop (mm)';
        //choptions['xaxis']['categories'] = Array.from({length: 8}, (_, i) => i * 1.953125);
        choptions['xaxis']['categories'] = Array.from({length: 8}, (_, i) => i );
        if (this.healthhash != null){
          const p1statslen = this.daqdata[this.p1stats_stcvsrevprox_i].length;
          //const p2statslen = this.daqdata[this.p2stats_stcvsfwdprox_i].length;
          choptions['xaxis']['categories'] = Array.from({length: p1statslen}, (_, i) => i );
        }
        //choptions['xaxis']['labels']['formatter'] = this.chart_xaxis_pressure_format;
        choptions['yaxis']['title']['text'] = 'Strokes';
        choptions['xaxis']['labels']['formatter'] = this.chart_xaxis_distance_format;
        choptions['yaxis']['labels']['formatter'] = this.chart_yaxis_format;
        choptions['tooltip']['x']['formatter'] = this.chart_tooltip_xaxis_proxvsst_format; 
        choptions['tooltip']['y']['formatter'] = this.chart_tooltip_yaxis_format;  
        return choptions;
      },
      ChartOptions_tempESPCMStats(){
        var choptions = JSON.parse(JSON.stringify(this.ChartOptions));
        choptions['title'] = {'text': 'ESPCM Temperature'};
        choptions['xaxis']['categories'] = Array.from({length: 15}, (_, i) => i * 8);
        choptions['xaxis']['labels']['formatter'] = this.chart_xaxis_temp_format;
        choptions['yaxis']['title']['text'] = 'Hours';
        choptions['yaxis']['labels']['formatter'] = this.chart_yaxis_format;
        choptions['tooltip']['x']['formatter'] = this.chart_tooltip_xaxis_temp_format; 
        choptions['tooltip']['y']['formatter'] = this.chart_tooltip_yaxis_format;  
        return choptions;
      },
      ChartOptions_tempDriveStats(){
        var choptions = JSON.parse(JSON.stringify(this.ChartOptions));
        choptions['title'] = {'text': 'Drive Temperature'};
        choptions['xaxis']['categories'] = Array.from({length: 15}, (_, i) => i * 8);
        choptions['xaxis']['labels']['formatter'] = this.chart_xaxis_temp_format;
        choptions['yaxis']['title']['text'] = 'Hours';
        choptions['yaxis']['labels']['formatter'] = this.chart_yaxis_format;
        choptions['tooltip']['x']['formatter'] = this.chart_tooltip_xaxis_temp_format; 
        choptions['tooltip']['y']['formatter'] = this.chart_tooltip_yaxis_format;  
        return choptions;
      },
      ChartOptions_tempMotorStats(){
        var choptions = JSON.parse(JSON.stringify(this.ChartOptions));
        choptions['title'] = {'text': 'Motor Temperature'};
        choptions['xaxis']['categories'] = Array.from({length: 15}, (_, i) => i * 8);
        choptions['xaxis']['labels']['formatter'] = this.chart_xaxis_temp_format;
        choptions['yaxis']['title']['text'] = 'Hours';
        choptions['yaxis']['labels']['formatter'] = this.chart_yaxis_format;
        choptions['tooltip']['x']['formatter'] = this.chart_tooltip_xaxis_temp_format; 
        choptions['tooltip']['y']['formatter'] = this.chart_tooltip_yaxis_format;      
        return choptions;
      },
      ChartOptions_tempOilHotStats(){
        var choptions = JSON.parse(JSON.stringify(this.ChartOptions));
        choptions['title'] = {'text': 'OilHot Temperature'};
        choptions['xaxis']['categories'] = Array.from({length: 15}, (_, i) => i * 8);
        choptions['xaxis']['labels']['formatter'] = this.chart_xaxis_temp_format;
        choptions['yaxis']['title']['text'] = 'Hours';
        choptions['yaxis']['labels']['formatter'] = this.chart_yaxis_format;
        choptions['tooltip']['x']['formatter'] = this.chart_tooltip_xaxis_temp_format; 
        choptions['tooltip']['y']['formatter'] = this.chart_tooltip_yaxis_format;      
        return choptions;
      },
      ChartOptions_tempOilColdStats(){
        var choptions = JSON.parse(JSON.stringify(this.ChartOptions));
        choptions['title'] = {'text': 'OilCold Temperature'};
        choptions['xaxis']['categories'] = Array.from({length: 15}, (_, i) => i * 8);
        choptions['xaxis']['labels']['formatter'] = this.chart_xaxis_temp_format;
        choptions['yaxis']['title']['text'] = 'Hours';
        choptions['yaxis']['labels']['formatter'] = this.chart_yaxis_format;
        choptions['tooltip']['x']['formatter'] = this.chart_tooltip_xaxis_temp_format; 
        choptions['tooltip']['y']['formatter'] = this.chart_tooltip_yaxis_format;      
        return choptions;
      },
      ChartOptions_tempCoolWaterStats(){
        var choptions = JSON.parse(JSON.stringify(this.ChartOptions));
        choptions['title'] = {'text': 'CoolingWater Temperature'};
        choptions['xaxis']['categories'] = Array.from({length: 15}, (_, i) => i * 8);
        choptions['xaxis']['labels']['formatter'] = this.chart_xaxis_temp_format;
        choptions['yaxis']['title']['text'] = 'Hours';
        choptions['yaxis']['labels']['formatter'] = this.chart_yaxis_format;
        choptions['tooltip']['x']['formatter'] = this.chart_tooltip_xaxis_temp_format; 
        choptions['tooltip']['y']['formatter'] = this.chart_tooltip_yaxis_format;      
        return choptions;
      },
      psiTimeStatsSeries() {
        var statsseries = [{
              name: "Pump (Hours)",
              //data: Array.from({length: 20}, () => 0)
              data: Array.from({length: 19}, () => 0)
            }];
        if(this.healthhash != null) {
          const statslen = this.daqdata[this.stats_tvspsi_i].length;
          //statsseries[0]['data']=Array.from({length: 20}, (_,i) => this.daqdata[this.stats_tvspsi_i][i]/3600);
          statsseries[0]['data']=Array.from({length: statslen-1}, (_,i) => this.daqdata[this.stats_tvspsi_i][i+1]/3600);
        }
        return statsseries;
      },
      psiStrokeStatsSeries() {
        var statsseries = [{
              name: 'Pump 1',
              //data: Array.from({length: 20}, () => 0)
              data: Array.from({length: 19}, () => 0)
            }, {
              name: 'Pump 2',
              //data: Array.from({length: 20}, () => 0)
              data: Array.from({length: 19}, () => 0)
            }];
        if(this.healthhash != null) {
          statsseries[0]['data']=this.daqdata[this.p1stats_stcvspsi_i].slice(1);
          statsseries[1]['data']=this.daqdata[this.p2stats_stcvspsi_i].slice(1);
        }
        return statsseries;
      },
      strokesFwdProxStatsSeries() {
        var statsseries = [{
              name: 'Pump 1',
              data: Array.from({length: 8}, () => 0)
            }, {
              name: 'Pump 2',
              data: Array.from({length: 8}, () => 0)
            }];
        if(this.healthhash != null) {
          statsseries[0]['data']=this.daqdata[this.p1stats_stcvsfwdprox_i];
          statsseries[1]['data']=this.daqdata[this.p2stats_stcvsfwdprox_i];
        }
        return statsseries;
      },
      strokesRevProxStatsSeries() {
        var statsseries = [{
              name: 'Pump 1',
              data: Array.from({length: 8}, () => 0)
            }, {
              name: 'Pump 2',
              data: Array.from({length: 8}, () => 0)
            }];
        if(this.healthhash != null) {
          statsseries[0]['data']=this.daqdata[this.p1stats_stcvsrevprox_i];
          statsseries[1]['data']=this.daqdata[this.p2stats_stcvsrevprox_i];
        }
        return statsseries;
      },
      temperatureESPCMseries() {
        var tempseries = [{
              name: 'Pump 1',
              data: Array.from({length: 15}, () => 0)
            }, {
              name: 'Pump 2',
              data: Array.from({length: 15}, () => 0)
            }];
        if(this.healthhash != null) {
          tempseries[0]['data']=Array.from({length: 15}, (_,i) => this.daqdata[this.p1stats_tempespcm_i][i]/3600);
          tempseries[1]['data']=Array.from({length: 15}, (_,i) => this.daqdata[this.p2stats_tempespcm_i][i]/3600);
        }
        return tempseries;
      },
      temperatureDriveseries() {
        var tempseries = [{
              name: 'Pump 1',
              data: Array.from({length: 15}, () => 0)
            }, {
              name: 'Pump 2',
              data: Array.from({length: 15}, () => 0)
            }];
        if(this.healthhash != null) {
          tempseries[0]['data']=Array.from({length: 15}, (_,i) => this.daqdata[this.p1stats_tempdrive_i][i]/3600);
          tempseries[1]['data']=Array.from({length: 15}, (_,i) => this.daqdata[this.p2stats_tempdrive_i][i]/3600);
        }
        return tempseries;
      },
      temperatureMotorseries() {
        var tempseries = [{
              name: 'Pump 1',
              data: Array.from({length: 15}, () => 0)
            }, {
              name: 'Pump 2',
              data: Array.from({length: 15}, () => 0)
            }];
        if(this.healthhash != null) {
          tempseries[0]['data']=Array.from({length: 15}, (_,i) => this.daqdata[this.p1stats_tempmotor_i][i]/3600);
          tempseries[1]['data']=Array.from({length: 15}, (_,i) => this.daqdata[this.p2stats_tempmotor_i][i]/3600);
        }
        return tempseries;
      },
      temperatureOilHotseries() {
        var tempseries = [{
              data: Array.from({length: 15}, () => 0)
            }];
        if(this.healthhash != null) {
          tempseries[0]['data']=Array.from({length: 15}, (_,i) => this.daqdata[this.stats_tvsohot_i][i]/3600);
        }
        return tempseries;
      },
      temperatureOilColdseries() {
        var tempseries = [{
              data: Array.from({length: 15}, () => 0)
            }];
        if(this.healthhash != null) {
          tempseries[0]['data']=Array.from({length: 15}, (_,i) => this.daqdata[this.stats_tvsocold_i][i]/3600);
        }
        return tempseries;
      },
      temperatureCoolWaterInseries() {
        var tempseries = [{
              data: Array.from({length: 15}, () => 0)
            }];
        if(this.healthhash != null) {
          tempseries[0]['data']=Array.from({length: 15}, (_,i) => this.daqdata[this.stats_tvswaterin_i][i]/3600);
        }
        return tempseries;
      },
    },
    methods: {
      adjust_graphs_width(){
        if (window.innerWidth > 1280) this.graphwidth = 600;
        else if (window.innerWidth > 960) this.graphwidth = 450;
        else this.graphwidth = window.innerWidth-100;
      },
      get_hoursmins_format(numminsecs, isMins=false) {
        var minsint = 0;
        if (isMins) {
          minsint = parseInt(numminsecs);
        } else {
          const secsint = parseInt(numminsecs);
          minsint =  Math.floor(secsint/60);
        }
        const hrsint  = Math.floor(minsint/60);
        minsint = minsint - (hrsint*60);
        return hrsint.toString()+"h "+ minsint.toString()+"m"
      },
      chart_xaxis_pressure_format (value) {
        //console.log(value);
        const fmtval = value+"-"+(value+4)+ "KSI";
        return fmtval;
      },
      chart_xaxis_distance_format (index) {
        const value = index * 1.953125;
        //console.log(value);
        if (index == 0)
          return "< "+ (value+1.953125).toFixed(1);
        else if (index == 7)
          return "> "+ value.toFixed(1) ;
        else {
          const fmtval = value.toFixed(1)+"-"+(value+1.953125).toFixed(1);
          return fmtval;
        }
      },
      chart_xaxis_temp_format (value) {
        //console.log(value);
        const fmtval = value+"-"+(value+8)+ "°C";
        return fmtval;
      },
      chart_yaxis_format (value) {
        //console.log(value);
        var fmtval = value;
        if(value > 1E12) fmtval = (value/1E12) + "T";
        else if(value > 1E9) fmtval = (value/1E9) + "B";
        else if(value > 1E6) fmtval = (value/1E6) + "M";
        else if(value > 1E3) fmtval = (value/1E3) + "K";
        return fmtval;
      },
      chart_tooltip_xaxis_pvst_format (value) {
        //console.log(value);
        const fmtval = "Duration with " +value.toFixed(3)+"-"+(value+4).toFixed(3)+ "KSI";
        return fmtval;
      },
      chart_tooltip_xaxis_pvsst_format (value) {
        //console.log(value);
        const fmtval = "Strokes in " +value.toFixed(3)+"-"+(value+4).toFixed(3)+ "KSI";
        return fmtval;
      },
      chart_tooltip_xaxis_proxvsst_format (index) {
        const value = index * 1.953125;
        //console.log(value);
        const fmtval = "Strokes in " +value.toFixed(1)+"-"+(value+1.953125).toFixed(1)+ "mm";
        return fmtval;
      },
      chart_tooltip_xaxis_temp_format (value) {
        //console.log(value);
        const fmtval = "Hours in " +value+"-"+(value+8)+ "°C";
        return fmtval;
      },
      chart_tooltip_yaxis_format (value) {
        //console.log(value);
        var fmtval = value.toFixed(2);
        if(value > 1E12) fmtval = (value/1E12).toFixed(12) + "T";
        else if(value > 1E9) fmtval = (value/1E9).toFixed(9) + "B";
        else if(value > 1E6) fmtval = (value/1E6).toFixed(6) + "M";
        else if(value > 1E3) fmtval = (value/1E3).toFixed(3) + "K";
        return fmtval;
      },

      recalculate_indicies(){
        this.appfwveri = this.healthprofile.indexOf("app.applicationInfo.codeInformationBlock_App.stVersionInformation.au8VersionFullString");
        this.bootldrfwveri = this.healthprofile.indexOf("app.applicationInfo.codeInformationBlock_BootLoader.stVersionInformation.au8VersionFullString");
        
        this.cmsernumi = this.healthprofile.indexOf("app.machine.s32ESPCMSerialNumber");
        this.psernumpi = this.healthprofile.indexOf("app.machine.machineConfiguration.enPumpSerialNumberPrefix");
        this.psernumi = this.healthprofile.indexOf("app.machine.machineConfiguration.u16PumpSerialNumber");
        this.pvarianti = this.healthprofile.indexOf("app.parameterManager.enPumpVariant");
        this.instpcounti = this.healthprofile.indexOf("app.machine.multiplePumpManager.u8InstalledPumpCount");
        this.enpumpflgsi = this.healthprofile.indexOf("app.machine.multiplePumpManager.u8PumpEnableFlags");
        this.timetotalrunhmii = this.healthprofile.indexOf("app.hmiViewModel.s32TotalRunTimeFromHMI_Minutes");
        this.timetotalruni = this.healthprofile.indexOf("app.machine.sapController.u32TotalRuntime_1024msec");
        this.timeoni = this.healthprofile.indexOf("app.machine.u32TotalOntime_1024msec");
        this.stats_tvspsi_i = this.healthprofile.indexOf("app.machine.sapController.pressureEstimator.au32TimeVsPressureStats");
        this.stats_tvsohot_i = this.healthprofile.indexOf("app.machine.sapController.machineOilSystemController.oilSystemObserver.au32TemperatureTimeStats_OilHot");
        this.stats_tvsocold_i = this.healthprofile.indexOf("app.machine.sapController.machineOilSystemController.oilSystemObserver.au32TemperatureTimeStats_OilCold");
        this.stats_tvswaterin_i = this.healthprofile.indexOf("app.machine.sapController.machineOilSystemController.oilSystemObserver.au32TemperatureTimeStats_CoolingWaterIn");

        this.p1_zpulse_offseti = this.healthprofile.indexOf("app.machine.pump1.driveController.commutationManager.s16IndexPulseOffset_E5_11");
        this.p1strokeleni = this.healthprofile.indexOf("app.machine.pump1.actuatorController.pumpHLF.hlfHoming.s32StrokeLength_Calculated");
        this.p1strokecounti = this.healthprofile.indexOf("app.machine.pump1.actuatorController.pumpHLF.hlfStroking.u32StrokeCount");
        //this.p1_backdrive_velfwd_i = this.healthprofile.indexOf("app.machine.pump1.actuatorController.jointObserver.s32Backdrive_PeakVelocity_Forward");
        //this.p1_backdrive_velrev_i = this.healthprofile.indexOf("app.machine.pump1.actuatorController.jointObserver.s32Backdrive_PeakVelocity_Reverse");
        this.p1stats_stcvspsi_i = this.healthprofile.indexOf("app.machine.pump1.actuatorController.pumpHLF.hlfStroking.au32StrokeCountVsPressureStats");
        this.p1stats_tempdrive_i = this.healthprofile.indexOf("app.machine.pump1.actuatorController.pumpThermalSupervisor.au32TemperatureTimeStats_Drive");
        this.p1stats_tempespcm_i = this.healthprofile.indexOf("app.machine.pump1.actuatorController.pumpThermalSupervisor.au32TemperatureTimeStats_ESPCM");
        this.p1stats_tempmotor_i = this.healthprofile.indexOf("app.machine.pump1.actuatorController.pumpThermalSupervisor.au32TemperatureTimeStats_Motor");
        this.p1stats_stcvsfwdprox_i = this.healthprofile.indexOf("app.machine.pump1.actuatorController.pumpHLF.hlfStroking.au32StrokeCountVsFwdEndProximitiStats");
        this.p1stats_stcvsrevprox_i = this.healthprofile.indexOf("app.machine.pump1.actuatorController.pumpHLF.hlfStroking.au32StrokeCountVsRevEndProximitiStats");

        this.p2_zpulse_offseti = this.healthprofile.indexOf("app.machine.pump2.driveController.commutationManager.s16IndexPulseOffset_E5_11");
        this.p2strokeleni = this.healthprofile.indexOf("app.machine.pump2.actuatorController.pumpHLF.hlfHoming.s32StrokeLength_Calculated");
        this.p2strokecounti = this.healthprofile.indexOf("app.machine.pump2.actuatorController.pumpHLF.hlfStroking.u32StrokeCount");
        //this.p2_backdrive_velfwd_i = this.healthprofile.indexOf("app.machine.pump2.actuatorController.jointObserver.s32Backdrive_PeakVelocity_Forward");
        //this.p2_backdrive_velrev_i = this.healthprofile.indexOf("app.machine.pump2.actuatorController.jointObserver.s32Backdrive_PeakVelocity_Reverse");        
        this.p2stats_stcvspsi_i = this.healthprofile.indexOf("app.machine.pump2.actuatorController.pumpHLF.hlfStroking.au32StrokeCountVsPressureStats");
        this.p2stats_tempdrive_i = this.healthprofile.indexOf("app.machine.pump2.actuatorController.pumpThermalSupervisor.au32TemperatureTimeStats_Drive");
        this.p2stats_tempespcm_i = this.healthprofile.indexOf("app.machine.pump2.actuatorController.pumpThermalSupervisor.au32TemperatureTimeStats_ESPCM");
        this.p2stats_tempmotor_i = this.healthprofile.indexOf("app.machine.pump2.actuatorController.pumpThermalSupervisor.au32TemperatureTimeStats_Motor");
        this.p2stats_stcvsfwdprox_i = this.healthprofile.indexOf("app.machine.pump2.actuatorController.pumpHLF.hlfStroking.au32StrokeCountVsFwdEndProximitiStats");
        this.p2stats_stcvsrevprox_i = this.healthprofile.indexOf("app.machine.pump2.actuatorController.pumpHLF.hlfStroking.au32StrokeCountVsRevEndProximitiStats");
      },

      get_health_report(pumpip,datestr) {
        var utcdatestr = this.convertLocalDateToUTCDate(datestr);

        this.$api.post(this.$olsupervisorurl + '/health/report', 
        {'code':'get_health_report', "pumpip":this.$route.query["pumpip"], "date":utcdatestr})
        .then(response => {
          //console.log(response.data);
          if (response.data['code'] == 'get_health_report') {
            if (response.data['success'])
            {
              const healthreport = response.data['report'];
              if (healthreport != null) {
                this.pumpip = healthreport['pumpip'];
                this.pumpcname = healthreport['cname'];
                this.pumpnname = healthreport['nname'];
                this.healthprofile = healthreport['healthprofile'];
                this.connstate = healthreport['connstate'];
                this.daqdata = healthreport['daqdata'];
                this.healthhash = healthreport['healthhash'];
                this.recalculate_indicies();
                this.nondefaultslist = healthreport['nondefaults']
              } else {
                this.pumpip = this.$route.query["pumpip"];
                this.pumpcname = null;
                this.pumpnname = null;
                this.connstate = {};
                this.daqdata = [];
                this.healthhash = null;
                this.healthprofile = [];
                this.report_text = "Data not available!";
              }
            } else {
              this.healthhash = null;
              this.report_text = this.$route.query["pumpip"] + " - " + response.data['errmsg'];
            }
          } else {
            this.report_text = "Invalid response!";
          }
        });
      }
    },
    created(){
      this.convertLocalDateToUTCDate = dateutils.convertLocalDateToUTCDate;
      this.convertUTCDateToLocalDate = dateutils.convertUTCDateToLocalDate;
    
      this.adjust_graphs_width();
      window.addEventListener("resize",this.adjust_graphs_width);
    },
    destroyed(){
      window.removeEventListener("resize",this.adjust_graphs_width);
    },
    mounted(){
      const pumpip = this.$route.query["pumpip"];
      this.get_health_report(pumpip,this.requestdate);
    },    
  }
</script>
<style scoped>
@media print {
    .v-btn, .v-input {
        visibility: hidden;
    }
}
</style>