<template>
<div id="unknownrouters" style="height:86vh">
    <v-toolbar class="mt-2" flat>
      <v-toolbar-title>Unknown Routers ({{ num_found_routers }})</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click.prevent="refresh_routerslist">Refresh</v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    
    <LoadingBar v-if="datafetch_in_progress" />
    <v-container v-else fluid class="fill-height">
    <v-row class="fill-height" justify="space-between" >
      <v-col cols="12" class="fill-height">
        <v-card class="fill-height" cols="9">
          <AzureMap class="AzureMap" :zoom="1.75">
            <AzureMapZoomControl />
            <AzureMapDataSource>
               <AzureMapSymbolLayer :options="symbolLayerOptions" @mousedown="onMouseDown"/>

                <template v-for="router,idx in routerslist">
                  <!-- Add Points to the Data Source -->
                  <AzureMapPoint :key="`MapPoint-${idx}`" :properties="router" 
                    :longitude="router['longitude']" :latitude="router['latitude']" />
                  <!-- Add a Popup to the map for every Point -->
                  <AzureMapPopup :key="`Popup-Pump-${idx}`" v-model="router.isPopupOpen"
                    :position="[router['longitude'], router['latitude']]"
                    :pixel-offset="[0, -18]" :close-button="true" class="AzureMapPopup" >
                      <p>
                        Location: {{ router['location'] }}<br/>
                        Global IP: {{ router['globalip'] }}<br/>
                        Connected Since: {{ router['connectedsince'] }}<br/>
                        Last Online: {{ router['lastonline'] }}<br/>
                      </p>
                  </AzureMapPopup>
                </template>
            </AzureMapDataSource>
            <AzureMapDataSource  :cluster="true" :cluster-radius="45" >
              <AzureMapBubbleLayer :options="bubbleLayerOptions" @mousedown="onClusterClicked"/>
                <template v-for="router,idx in routerslist">
                  <!-- Add Points to the Data Source -->
                  <AzureMapPoint :key="`MapPoint-${idx}`" :properties="router" 
                    :longitude="router['longitude']" :latitude="router['latitude']" />
                  <!-- Add a Popup to the map for every Point -->
                  <AzureMapPopup :key="`Popup-Cluster-${idx}`" :close-button="true" class="AzureMapPopup" >
                  </AzureMapPopup>
                </template>
              <AzureMapSymbolLayer :options="textLayerOptions"/>
            </AzureMapDataSource>
          </AzureMap>
        </v-card>
      </v-col>
    </v-row>
    </v-container>
</div>
</template>

<script>
  import dateutils from '../plugins/date_utilities';
  import LoadingBar from "../components/LoadingBar";

  import Vue from 'vue'
  import VueAzureMaps from 'vue-azure-maps'
  Vue.use(VueAzureMaps, {
    key: 'z_J8-o-wDXkdbamCk_DtB4MIEY5nrPvVWJ6tn2aVrpI',
  })

  import { AzureMap, AzureMapDataSource, AzureMapZoomControl,AzureMapPopup,AzureMapSymbolLayer,
   AzureMapPoint, AzureMapBubbleLayer } from 'vue-azure-maps';

  export default {
    name: "UnknownRoutersPage",

    components: {
      AzureMap,AzureMapZoomControl,AzureMapSymbolLayer,
      AzureMapDataSource,
      AzureMapPoint,AzureMapPopup,AzureMapBubbleLayer,
      LoadingBar
    },
    data: () => ({
      routerslist: [],      
      datafetch_in_progress: false,

      mapdialog: false,
      symbolLayerOptions: {
        iconOptions: {
          ignorePlacement: true,
          allowOverlap: true,
          image: 'pin-blue',
        },
        filter: ['!', ['has', 'point_count']]
      },

      bubbleLayerOptions: {
                createIndicators: true, // to enable bubble layer a11y feature
        radius: [
          'step',
          ['get','point_count'],
          10, // Default 20px radius
          2,15, // If point_count >= 2, radius=30px
          5,25  // If point_count >= 5, radius=30px
        ],
        strokeColor: '#4288f7',
        strokeWidth: 2,
        color: [
          'step',
          ['get','point_count'],
          'rgba(0,128,255,0.8)', // Default to green
          2,'rgba(0,255,255,0.8)',
          5,'rgba(0,255,0,0.8)',
        ],
        filter: ['has','point_count']
      },

      textLayerOptions:{
        iconOptions: {
          image: 'none' //Hide the icon image.
        },
        textOptions: {
          textField: ['get', 'point_count'],
          offset: [0, 0.4]
        }
      },
    }),
    created() {
      this.convertUTCDateToReadableLocalDate = dateutils.convertUTCDateToReadableLocalDate;
    },
    mounted() {
      this.get_unknown_routers();
    },
    computed: {
      num_found_routers() {
        return this.routerslist.length;
      }
    },
    methods: {
      refresh_routerslist(){
        this.get_unknown_routers();
      },
      get_unknown_routers() {
        this.routerslist=[];
        this.datafetch_in_progress = true;
        //console.log("get_pumps_online - start - ", new Date());
        this.$api.post(this.$olsupervisorurl + '/unknownrouters')
        .then(response => {
          //console.log(response.data);
          if (response.data["code"] == "list_unknown_routers")
          {
            if (response.data["success"]) {
              const onlinerouters = response.data["routerlist"];
              //var routerslist = [];
              for(var idx in onlinerouters){
                const routerdict = onlinerouters[idx];
                routerdict["longitude"] = parseFloat(routerdict["longitude"]);
                routerdict["latitude"] = parseFloat(routerdict["latitude"]);
                routerdict["connectedsince"] = this.convertUTCDateToReadableLocalDate(routerdict["connectedsince"]);
                routerdict["lastonline"] = this.convertUTCDateToReadableLocalDate(Date(routerdict["lastonline"]));
                //routerslist.push(routerdict);
              }
              this.routerslist=onlinerouters;
              //console.log(routerslist);
            } else {
              alert(response.data['errmsg']);
            }
          } else {
              alert("Invalid response!");
          }
          //this.$emit('loadcomplete');
          this.datafetch_in_progress = false;
        });
      },
      onMouseEnter(e) {
        console.log("onMouseEnter", e);
      },
      onMouseLeave(e) {
        console.log("onMouseLeave", e);
      },
      onMouseDown(e) {
        //console.log("onMouseDown", e);
        if (e.shapes && e.shapes.length > 0) {
          // Capture the selected shape.
          const selectedShape = e.shapes[0]
          // Check if the point is in our data
          const selectedrouter = this.routerslist.find((r) => r.globalip === selectedShape.data.properties.globalip);
          //console.log(selectedpump)
          if (selectedrouter) {
            // Capture the selected point.
            //this.selectedPoint = selectedpumpselectedpump
            //console.log(selectedpump);
            // Show the popup
            Vue.set(selectedrouter, "isPopupOpen", true);
            //selectedpump.isPopupOpen = true;
          }
        }
      },

      onClusterClicked(e){
        if (e && e.shapes && e.shapes.length > 0 && e.shapes[0].properties.cluster) {
          console.log(e);
        }
      },
         
    }
  }
</script>

<style scoped>
.AzureMap {
  width: 100%;
  height: 100%;
}
.AzureMapPopup {
  max-width: 240px;
  padding: 1rem;
}
</style>